import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";
import { getPageData } from '@/data/pageData';
import { CodeIcon, BoltIcon, LayersIcon, SettingsIcon } from "@/components/icons/icons";
import { Link } from "gatsby";
import FeatureCard from "@/components/cards/FeatureCard";
import FWNavBar from "@/components/navbar/FullWidthNavBar";
import InLineNavBar from "@/components/navbar/InLineNavBar";
import config from '@/utils/config';
import { MagneticText } from "@/components/utility/MagneticText";
import CountdownTimer from "@/components/utility/Countdown";
import { Button } from "@/components/ui/button";
import ScrollRevealWrapper from "@/components/utility/ScrollRevealWrapper";

const Header = () => {
  const location = useLocation();
  const { backgroundImage, heading } = getPageData(location.pathname);

  const data = useStaticQuery(graphql`
    query {
      girlBrushing: file(relativePath: { eq: "girl-brushing.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      boyBrushing: file(relativePath: { eq: "boy-brushing.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      tooth: file(relativePath: { eq: "tooth.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  // Array of images using getImage to retrieve each image
  const images = [
    { image: getImage(data.girlBrushing), alt: "Girl Brushing" },
    { image: getImage(data.boyBrushing), alt: "Boy Brushing" },
    { image: getImage(data.tooth), alt: "Tooth" },
  ];

  // Randomly select one image from the array
  const randomImage = images[Math.floor(Math.random() * images.length)];

  const magneticHeading = heading || "";
  let featureSection;

  if (location.pathname === "/") {
    featureSection = (
      <section className="py-12 md:py-20 relative z-10">
        <div className="container mx-auto grid grid-cols-1 gap-6 px-6 md:grid-cols-2 lg:grid-cols-4">
          <FeatureCard
            Icon={CodeIcon}
            title="Rapid Development"
            description="Streamline your development process with our powerful tools and frameworks."
          />
          <FeatureCard
            Icon={BoltIcon}
            title="Blazing Fast"
            description="Our optimized infrastructure ensures lightning-fast performance for your apps."
          />
          <FeatureCard
            Icon={LayersIcon}
            title="Scalable Solutions"
            description="Easily scale your applications to handle growing traffic and user demands."
          />
          <FeatureCard
            Icon={SettingsIcon}
            title="Dedicated Support"
            description="Our expert team is here to help you every step of the way, 24/7."
          />
        </div>
      </section>
    );
  }

  return (
    <>
      <header className="relative text-white">
        <div className="container flex flex-col md:flex-row justify-between items-center relative md:py-10 z-20">
          <div className="order-2 md:order-1 mt-10 md:mt-0">
            <Link to="/">
              <StaticImage
                src="../images/anzspd-logo.svg"
                alt="Logo"
                width={400}
                layout="constrained"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
              />
            </Link>
          </div>
          <div className="bg-c3 p-6 rounded-b-3xl relative md:absolute top-0 right-0 md:pt-12 order-1 md:order-2">
            <div className="text-center font-bold text-2xl mb-3">Event starts in</div>
            <CountdownTimer targetDate="2025-03-28" />
          </div>
        </div>
        {config.inlineNavbar ? <InLineNavBar /> : <FWNavBar />}

        {location.pathname === "/" ? (
        <section className="bg-gradient-to-tr from-slate-50 to-slate-300 relative mx-auto z-10 pt-20 pb-20 lg:pb-40 mt-12 lg:mt-0">
          <div className="container">
            <div>
              <h1 className="bg-gradient-to-r from-c5 to-c1 bg-clip-text text-transparent">9th Biennial RK HALL</h1>
              <h2 className="bg-gradient-to-r from-c2 to-lime-400 bg-clip-text text-transparent">Paediatric Dentistry Lecture Series 2025</h2>
              <h3 className="bg-gradient-to-r from-c3 to-c4 bg-clip-text text-transparent">
                  Innovations and Interdisciplinary Approaches in Paediatric Dentistry
              </h3>

            </div>
          </div>
          <div className="custom-shape-divider-bottom-1728596303">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
            </svg>
          </div>
        </section>
        ) : null}

        <section
          className="relative z-10 py-20"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="relative container mx-auto z-10 pb-20 lg:pb-40 xl:pb-80">
            {location.pathname === "/" ? (
              <>
                  <div className="relative z-10">
                    <ScrollRevealWrapper>
                      <div className="space-y-4 text-black">
                        <p className="font-bold text-2xl">Dear Colleagues and Guests,</p>
                        <p>It is my pleasure to welcome you all to this year’s RK Hall Lecture Series, presented by the Australia and New Zealand Society of Paediatric Dentistry Queensland. I am Sobia Zafar, President of the Society, and I am honoured to be hosting such an esteemed gathering of professionals, innovators, and thought leaders in the field of paediatric dentistry.</p>
                        <p>This year, we are delighted to hold the lecture series in the beautiful city of Brisbane. Known for its vibrant cultural scene, stunning riverfront, and relaxed yet lively atmosphere, Brisbane offers a perfect blend of urban sophistication and natural beauty. I encourage you to take some time to explore its attractions, from the South Bank’s lush parklands and iconic Brisbane River to its diverse dining experiences and rich heritage.</p>
                        <p>Our theme, Innovations and Interdisciplinary Approaches in Paediatric Dentistry, reflects the growing need for collaboration and forward-thinking solutions to meet the evolving challenges in our specialty. Our focus today is not only on cutting-edge technologies and methods but also on the power of interdisciplinary teamwork to enhance patient care and outcomes.</p>
                        <p>We are honoured to have Dr Nick Lygidakis as our keynote speaker. Dr Lygidakis, a global leader in paediatric dentistry, brings a wealth of knowledge and experience in advancing innovative treatment approaches and promoting collaborative care. His insights will undoubtedly provide valuable perspectives on the future of paediatric dentistry and inspire us to continue pushing the boundaries of our practice.</p>
                        <p>Together, we can redefine paediatric dentistry for the future, ensuring that our young patients receive the most advanced and comprehensive care possible. I look forward to the insightful discussions and exchange of ideas that will undoubtedly inspire and shape our practice moving forward.</p>
                        <p>Thank you for your participation, and I hope you find presentations both enriching and impactful.</p>
                        <p className="inline-block !mt-20">Warm regards,</p>
                        <div className="flex flex-row gap-6 items-center !mt-10">
                            <img
                              src="/images/Sobia-Zafar.jpg"
                              alt="Sobia Zafar"
                              className="w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] rounded-full"
                            />
                          <div>
                            <p className="font-bold text-lg lg:text-2xl mb-0">A/Prof Sobia Zafar  </p>
                            <p className="italic text-sm lg:text-base">BDS, MSc, DClinDent, PhD, FICD, FPFA, FDTFEd, SFHEA, MRACDS<br/>
                            President, Australia and New Zealand Society of Paediatric Dentistry Qld</p>
                          </div>
                        </div>
                      </div>
                      <Button asChild size="lg" className="text-2xl lg:text-3xl p-8 mt-20"><Link to="/registration">Register Now!</Link></Button>
                    </ScrollRevealWrapper>
                  </div>

                <StaticImage
                  src="../images/kid-brushing.png"
                  alt="Kid Brushing"
                  width={500}
                  layout="constrained"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                  className="!absolute right-0 bottom-[-80px] lg:bottom-[-50px] xl:bottom-0 opacity-20 xl:opacity-100 z-0"
                />
              </>
            ) : (
              <div className="absolute top-1/2 transform -translate-y-1/2">
                <MagneticText body={magneticHeading} as="div" className="text-c1 w-full max-w-2xl text-center font-var text-4xl md:text-6xl">
                  {(tokens) =>
                    tokens.map((token, index) => (
                      <MagneticText.Token key={index} body={token} className="inline-block cursor-default whitespace-pre" />
                    ))
                  }
                </MagneticText>
              </div>
            )}

            {location.pathname !== "/" ? (
              <GatsbyImage
                image={randomImage.image}
                alt={randomImage.alt}
                className="!absolute right-0 bottom-[-80px] lg:bottom-[-50px] xl:bottom-0 opacity-20 xl:opacity-100 w-[250px] lg:w-[300px] xl:w-[370px] z-0"
              />
            ) : null}
          </div>
          <div className="custom-shape-divider-bottom-1728596303">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
            </svg>
          </div>
        </section>

        {config.headerFeatureBoxes ? featureSection : null}
      </header>
    </>
  );
};

export default Header;
