import React from "react";
import { ScrollReveal } from "@/components/utility/ScrollReveal";
import clsx from "clsx";

export default function ScrollRevealWrapper({
  children,
  className = "",
  delay = 0,
  index = 0,
  ...props
}) {
  return (
    <ScrollReveal {...props}>
      {(isActive) => (
        <div
          style={{
            transitionDelay: `${(index + 1) * delay}ms`,
            transitionDuration: "500ms",
          }}
          className={clsx(
            className,
            "transition-all ease-in-out",
            isActive
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-8"
          )}
        >
          {children}
        </div>
      )}
    </ScrollReveal>
  );
}
