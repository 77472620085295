const pageData = {
    "/": {
      backgroundImage: "/images/anzspd-bg-invert.webp",
    },
    "/accommodation/": {
      heading: "Accommodation",
    },
    "/destinations/": {
      heading: "Destinations",
    },
    "/latest-news/": {
      heading: "Latest News",
    },
    "/sponsors-and-exhibitors/": {
      heading: "Sponsors & Exhibitors",
    },
    "/demo/": {
      heading: "Download Form",
    },
    "/registration/": {
      heading: "Registration",
    },
    "/program/": {
      heading: "Program",
    },
    "/history/": {
      heading: "History of RK Hall",
    },
    "/speakers/": {
      heading: "Speakers",
    },
    "/venue/": {
      heading: "Venue",
    },
    "/visit-brisbane/": {
      heading: "Visit Brisbane",
    },
    "/contact/": {
      heading: "Contact Us",
    },
  };

  export const getPageData = (path) => {
    const defaultData = {
      backgroundImage: "/images/anzspd-bg-invert.webp",
    };

    const data = pageData[path] || {};

    return {
      ...defaultData,
      ...data,
    };
  };
